import Banner from "../../component/contact/banner"
import ContactForm from "../../component/contact/contact-form"
const Contact = () => {
    return (
        <>
        <Banner />
        <ContactForm />
        </>

    )
}
export default Contact