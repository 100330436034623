import ReactPlayer from "react-player";
const Banner = () => {
  const video =
    "https://res.cloudinary.com/duhd0zz4q/video/upload/v1700732561/combined_clip_1_rrie0u.mp4";
  return (
    <>
      <div className="relative">
        <ReactPlayer
          url={video}
          controls={false}
          loop
          playing
          playsinline={true}
          muted={true}
          className="video-container"
        />
        <div className="absolute bottom-0 mx-[3%] md:mx-[5%] xl:mx-[8%] 2xl:mb-32 xl:mb-24 lg:mb-20 md:mb-16 sm:mb-8 mb-4 ">
          <h1 className="xl:text-[72px] lg:text-[60px] md:text-[42px] sm:text-[30px] text-[20px] font-semibold font-Suisse text-[#fff]  xl:leading-[74px] lg:leading-[58px] md:leading-[50px] sm:leading-[35px] leading-[28px] tracking-[1px]">
            Fueling the Future, <br />
            Strengthening Economies.
          </h1>
        </div>
      </div>
    </>
  );
};
export default Banner;
