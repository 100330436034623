import Banner from "../../component/products/banner";
import KoreanProduct from "../../component/products/korean-product";
import KeloneProduct from "../../component/products/kelone-product";
// import KeloneMud from "../../component/products/kelone-mud";
const Products = () => {
  return (
    <>
      <Banner />
      <KoreanProduct />
      <KeloneProduct />
      {/* <KeloneMud /> */}
    </>
  );
};
export default Products;
