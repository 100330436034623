const Service = () => {
  const data = [
    {
      id: 1,
      name: "Petroleum",
      subtitle:
        "Experienced oil and petroleum trading through transparent production analysis.",
      imagecss: "banner-service",
    },
    {
      id: 2,
      name: "Chemical industry",
      subtitle:
        "Specializing in the distribution of liquid and solid chemicals to industries.",
      imagecss: "banner-service1",
    },
    {
      id: 3,
      name: "Petrochemical",
      subtitle:
        "We help you gain the best overall insights on petrochemical production and cost analysis",
      imagecss: "banner-service2",
    },
    {
      id: 4,
      name: "Metals and Minerals Industry",
      subtitle:
        "Proficient in sourcing and delivering a wide spectrum of metals and minerals to various sectors.",
      imagecss: "banner-service3",
    },
    {
      id: 5,
      name: "Food Commodities",
      subtitle:
        "Expertise in the trade and distribution of food commodities, offering transparent sourcing and supply chain solutions.",
      imagecss: "banner-service4",
    },
  ];
  return (
    <div className="pb-[20px] 2xl:pt-[68px] xl:pt-[70px] lg:pt-[72px] pt-[50px] border-t-[1px] ">
      <div className="mx-[3%] md:mx-[5%] xl:mx-[8%]">
        <div className="flex lg:flex-row justify-between flex-wrap w-full ">
          {data?.map((item, index) => (
            <div
              className={`md:p-[24px] p-5 lg:w-[48.5%] w-full h-[295px] flex flex-col justify-between 2xl:mt-12 xl:mt-10 mt-8 ${item?.imagecss} transition ease-in-out delay-150 duration-200`}
              key={index}
            >
              <div className="banner-service-content ">
                <h3 className="font-Suisse font-semibold  md:text-[30px] text-[26px] mb-[18px]">
                  {item.name}
                </h3>
                <p className="md:text-[18px] text-[15px] text-[#000] font-semibold leading-[22px] font-Suisse pr-30">
                  {item.subtitle}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Service;
