const Banner = () => {
  return (
    <div className="mx-[3%] md:mx-[5%] xl:mx-[8%]">
      <h1 className="xl:text-[72px] lg:text-[65px] sm:text-[55px] text-[40px] font-Suisse font-semibold text-[#04128A] sm:leading-[74px]  leading-[52px] lg:text-right text-center lg:mt-[110px] mt-[80px] lg:mb-[80px] mb-[60px] ">
        Appointment Booking
      </h1>
    </div>
  );
};
export default Banner;
