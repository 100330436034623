const Mission = () => {
  return (
    <div className="bg-[#081022]">
      <div className="mx-[3%] md:mx-[5%] xl:mx-[8%] lg:pt-[110px] md:pt-[80px] pt-[60px] pb-[60px] lg:pb-[70px]">
        <h1 className="lg:text-[65px] sm:text-[55px] text-[40px] leading-[74px] font-Suisse font-semibold text-[#04128A] tracking-[-1px] lg:text-left text-center lg:mb-12 sm:mb-12 mb-8 text-[#F2BD00]">
          Mission
        </h1>
        <div className="lg:w-[70%] w-full">
          <h3 className="font-Suisse md:text-[39px] text-[25px] font-medium md:leading-[42px] leading-[35px] text-[#04128A] lg:text-left text-center text-[#FFFFFF]">
            We are committed to delivering excellence across a wide spectrum of
            industries with the knowledge, tools, and resources they need to
            navigate the complex world of trading.
          </h3>
        </div>
        <div className="flex justify-start">
          <div className="lg:w-[70%] w-full lg:mt-16 sm:mb-12 mb-8 mt-8">
            <p className="md:text-[18px] text-[16px] font-Suisse font-normal text-[#9FAAC1] leading-[27px] lg:text-left text-center">
              “Our mission is to provide top-quality products and services in
              the fields of Oil and Gas, Chemical industry, Metal & Minerals
              Industry, and Food Commodities. We strive to build lasting
              relationships with our partners and customers by offering
              innovative solutions, outstanding service, and unwavering
              dedication to quality and integrity. We aim to be a global leader
              in general trade and consulting, creating value for our clients
              and contributing to the growth of industries worldwide.”
            </p>
          </div>
        </div>
      </div>
      <div className="mx-[3%] md:mx-[5%] xl:mx-[8%]">
        <div className="bg-[#17284F] w-full py-[2px] rounded-2xl"></div>
      </div>
    </div>
  );
};
export default Mission;
