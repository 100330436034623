const Footer = () => {
  return (
    <div >
      <div className="md:py-[80px] sm:py-[60px] py-[40px] border-y-[1px] mt-24">
        <div className="mx-[3%] md:mx-[5%] xl:mx-[8%]">
          <h1 className="lg:text-[40px] md:text-[30px] sm:text-[24px] text-[18px] font-normal font-Suisse  lg:leading-[54px] sm:leading-[35px] leading-[25px] text-[#04128A] lg:text-left text-center">
            Your Needs Are Our Priority{" "}
            <span className=" text-[#777777]">- We're Here to Assist</span>
          </h1>
        </div>
      </div>
      <div className="mx-[3%] md:mx-[5%] xl:mx-[8%] flex justify-end md:py-[40px] py-[25px]">
        <div>
          <h6 className="font-Suisse  text-[14px] font-semibold text-[#0B0B0B]">
            Copyright ©2023. All rights reserved
          </h6>
        </div>
      </div>
    </div>
  );
};
export default Footer;
