import Banner from "../../component/about/banner";
import Section from "../../component/about/section";
import Mission from "../../component/about/mission";
import Vision from "../../component/about/vision";
const About = () => {
  return (
    <>
      <Banner />
      <Section />
      <Mission />
      <Vision />
    </>
  );
};
export default About;
