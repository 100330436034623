const Banner = () => {
  return (
    <div className="mx-[3%] md:mx-[5%] xl:mx-[8%] lg:mt-[110px] mt-[80px] lg:mb-[80px] mb-[60px]">
      <h1 className="xl:text-[72px] lg:text-[65px] sm:text-[55px] text-[40px] font-Suisse font-semibold text-[#04128A] sm:leading-[84px] leading-[52px] lg:text-right text-center  ">
        Sourcing <span className="text-[#F2BD00]">Quality</span> Globally,{" "}
        <br />
        Delivering Excellence Every Trade
      </h1>
      <div className="lg:w-[60%] w-full">
      <p className="pt-12 font-Suisse lg:text-[25px] md:text-[20px] text-[18px] font-medium md:leading-[35px] leading-[28px] text-[#666666] lg:text-left text-center">
      Our team comprises of highly skilled and experienced professionals with decades of expertise in the industrial materials and commodities sector. This collective knowledge positions us as a top contender in general trading. At Anza General Trading, our commitment to quality products and exceptional customer care is at the core of our corporate mission.
      </p>
      </div>
    </div>
  );
};
export default Banner;
