import { useNavigate } from "react-router-dom";

const OurExpertise = () => {
  // navigate
  const navigate = useNavigate();
  return (
    <div className="bg-[#0B152C] ">
      <div className=" mx-[3%] md:mx-[5%] xl:mx-[8%] py-[115px]  flex lg:flex-row flex-col border-b-4 border-[#17284F]">
        <div className="lg:w-[55%] w-full ">
          <h3 className="text-[#4A81FF] font-semibold md:text-[39px] text-[28px] leading-[48px] font-Suisse mb-[33px] lg:text-left text-center">
            A Culture of Excellence
          </h3>
          <h6 className="md:text-[25px] text-[18px] font-Suisse leading-[31px] font-medium text-[#fff] lg:text-left text-center">
            With 50 years of experience, we bring deep{" "}
            <br className="md:block hidden" /> industry knowledge.
          </h6>
        </div>
        <div className="lg:mt-0 pt-[40px] flex lg:justify-start justify-center ">
          <img
            src={require("../../assets/img/fifty.png")}
            alt=""
            className="lg:h-[260px] md:h-[200px] h-[160px]"
          />
        </div>
      </div>
      <div className="mx-[3%] md:mx-[5%] xl:mx-[8%] md:pt-[100px] md:pb-[100px] pt-[70px] pb-[70px]">
        <h1 className="text-[#fff] font-Suisse lg:text-[50px] text-[35px] font-semibold leading-[64px] lg:text-left text-center">
          Our Expertise
        </h1>
      </div>
      <div className="flex lg:flex-row flex-col ">
        <div className="lg:w-[50%] w-full overflow-hidden ">
          <div className="h-full">
            <img
              src={require("../../assets/img/burj.png")}
              alt=""
              className="image-zoom transition ease-in-out delay-150 duration-200 lg:h-full"
            />
          </div>
        </div>
        <div className="xl:px-[140px] lg:px-[70px] px-[40px] xl:py-[90px] lg:py-[50px] py-[40px] flex flex-col justify-between lg:w-[50%] w-full bg-[#081022]">
          <h3 className="md:text-[35px] text-[28px] font-medium leading-[45px] font-Suisse text-[#fff] lg:text-left text-center">
            Market Pioneers
          </h3>
          <div>
            <p className="md:text-[18px] text-[16px] font-medium leading-[24px] text-[#fff] mb-[40px] lg:text-left text-center opacity-[0.6] w-full lg:w-[80%]">
              We take pride in being true pioneers in the dynamic world of
              trading. Our journey has been marked by a relentless commitment to
              excellence, innovation, and a steadfast dedication to pushing the
              boundaries of what's possible in the industry.
              <br />
              <br />
              <b>Unwavering Commitment to Trading Excellence:</b>
              <br />
              With a rich history of navigating market trends and evolving trade
              landscapes, we have consistently demonstrated an unwavering
              commitment to trading excellence.
              {/* <br />
              <br />
              <b> Industry Leaders in Every Sense:</b>
              <br /> Our commitment to trading has not only shaped our identity
              but has also positioned us as true industry leaders.
              <br />
              <br />
              <b> Paving the Way for Future Generations: </b>
              <br /> We don't just focus on the present. At Anza, we are more
              than traders; we are architects of the future, shaping the
              industry for generations to come. Join us on this journey of
              innovation and leadership in the world of trading. */}
            </p>
            <div className="flex lg:justify-start justify-center">
              <button
                onClick={() => navigate("/contact")}
                className="px-[23.9px] pt-[14.34px] pb-[12.34px] md:text-[16px] text-[14px] bg-[#fff] font-Suisse text-[#081022] rounded-full transition ease-in-out delay-150 duration-200 hover:text-[#000] hover:bg-[#F2BD00]"
              >
                Leave Message
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col-reverse">
        <div className="xl:px-[140px] lg:px-[70px] px-[40px] xl:py-[90px] lg:py-[50px] py-[40px]  flex flex-col justify-between lg:w-[50%] w-full bg-[#081022]">
          <h3 className="md:text-[35px] text-[28px] font-medium leading-[45px] font-Suisse text-[#fff] lg:text-left text-center">
            Comprehensive Market Analysis
          </h3>
          <div>
            <p className="md:text-[18px] text-[16px] font-medium leading-[24px] text-[#fff] mb-[40px] lg:text-left text-center opacity-[0.6] w-full lg:w-[80%]">
              Our commitment to excellence extends to our unparalleled expertise
              in comprehensive market analysis. With a foundation built on years
              of invaluable experience, we take pride in offering a strategic
              advantage to all stakeholders through meticulous examination of
              market dynamics.
              <br />
              <br />
              <b>In-Depth Understanding:</b>
              <br />
              Our comprehensive approach ensures a nuanced understanding of
              market forces, enabling us to provide informed recommendations
              tailored to your specific needs.
              {/* <br />
              <br /> */}
              {/* <b> Maximizing Stakeholder Benefits:</b>
              <br />
              Our commitment to conducting thorough market analysis isn't just
              about data – it's about delivering maximum benefits to all
              stakeholders. Whether you're an investor seeking opportunities, a
              partner exploring collaborations, or a client looking for
              strategic insights, our analyses serve as the cornerstone for
              well-informed decision-making. Strategic Advantage
              <br />
              <br />
              <b>Strategic Advantage:</b>
              <br />
              Backed by our analytical prowess, clients gain a strategic
              advantage in a competitive landscape. We leverage data-driven
              insights to formulate agile and adaptive strategies, helping our
              partners thrive in dynamic markets and capitalize on emerging
              trends. Future-Focused Approach.
              <br />
              <br />
              Anza's commitment to comprehensive market analysis is
              forward-thinking. We don't just analyze the present; we anticipate
              and adapt to future market shifts, ensuring that our stakeholders
              are well-positioned to capitalize on evolving opportunities. Trust
              Anza for insightful analysis that propels you towards sustained
              success in an ever-changing business environment. */}
            </p>
            <div className="flex lg:justify-start justify-center">
              <button
                onClick={() => navigate("/contact")}
                className="px-[23.9px] pt-[14.34px] pb-[12.34px] md:text-[16px] text-[14px] bg-[#fff] font-Suisse text-[#081022] rounded-full transition ease-in-out delay-150 duration-200 hover:text-[#000] hover:bg-[#F2BD00]"
              >
                Leave Message
              </button>
            </div>
          </div>
        </div>
        <div className="lg:w-[50%] w-full overflow-hidden">
          <div className="h-full">
            <img
              src={require("../../assets/img/solution.jpg")}
              alt=""
              className="image-zoom transition ease-in-out delay-150 duration-200 lg:h-full"
            />
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col ">
        <div className="lg:w-[50%] w-full overflow-hidden">
          <div className="h-full">
            <img
              src={require("../../assets/img/focus.jpg")}
              alt=""
              className="image-zoom transition ease-in-out delay-150 duration-200 lg:h-full"
            />
          </div>
        </div>
        <div className="2xl:px-[140px] lg:px-[70px] px-[40px] 2xl:py-[90px] lg:py-[50px] py-[40px]  flex flex-col justify-between lg:w-[50%] w-full bg-[#081022]">
          <h3 className="md:text-[35px] text-[28px] font-medium leading-[45px] font-Suisse text-[#fff] lg:text-left text-center">
            Holistic Solutions
          </h3>
          <div>
            <p className="md:text-[18px] text-[16px] font-medium leading-[24px] text-[#fff] mb-[40px] lg:text-left text-center opacity-[0.6] w-full lg:w-[80%]">
              Our commitment to excellence goes beyond mere transactions – we
              provide holistic solutions that cater to the diverse needs of our
              clients
              <br />
              <br />
              <b>Diversified Portfolio:</b>
              <br />
              Explore a world of possibilities with our diverse range of
              services. From petroleum trading to strategic sector engagement,
              our portfolio is designed to address the multifaceted demands of
              the modern business landscape. We pride ourselves on being your
              one-stop solution provider.
              {/* <br />
              <br />
              <b>Petroleum Trading Expertise:</b>
              <br />
              As experts in petroleum trading, we bring unparalleled knowledge
              and experience to the table. Our strategic approach ensures
              seamless transactions, fostering enduring partnerships and
              maximizing value for clients navigating the complex energy market.
              <br />
              <br />
              <b>Petroleum Trading Expertise:</b>
              <br />
              Beyond trading, we actively engage with sectors, fostering
              collaboration, and driving innovation. Our sector engagement
              services extend beyond conventional boundaries, creating synergies
              that propel our clients towards sustained growth and success.
              <br />
              <br />
              <b>Tailored Solutions:</b>
              <br />
              Recognizing that each client is unique, we offer tailor-made
              solutions crafted to fit your specific requirements. Our team
              collaborates closely with you, understanding your goals and
              challenges to deliver bespoke services that align with your
              business objectives. */}
            </p>
            <div className="flex lg:justify-start justify-center">
              <button
                onClick={() => navigate("/contact")}
                className="px-[23.9px] pt-[14.34px] pb-[12.34px] md:text-[16px] text-[14px] bg-[#fff] font-Suisse text-[#081022] rounded-full transition ease-in-out delay-150 duration-200 hover:text-[#000] hover:bg-[#F2BD00]"
              >
                Leave Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurExpertise;
