import ReactPlayer from "react-player";
const FiveDecade = () => {
  const video =
    "https://res.cloudinary.com/duhd0zz4q/video/upload/v1700723568/Anza_Dubai_Clip_r1xfgp.mp4";
  return (
    <div className="mx-[3%] md:mx-[5%] xl:mx-[8%]  xl:my-[115px] sm:my-[85px] my-[45px] flex lg:flex-row flex-col justify-between">
      <div className="lg:mb-0 mt-[14px] mb-[20px] md:px-0 px-5">
        <img
          src={require("../../assets/img/right-arrow.png")}
          alt=""
          className="w-[48px]"
        />
      </div>
      <div className="lg:w-[65%] w-full md:px-0 px-5">
        <h1 className="md:text-[39px] text-[22px] font-normal font-Suisse text-[#858585] md:leading-[48px] leading-[30px]">
          With a <span className="text-[#04128A]">five-decade legacy</span> in
          the GG, Anza General Trading, headquartered in Dubai, stands as a
          prominent corporate entity in diverse industries.
        </h1>
        <div className="sm:my-[60px] my-[40px] ">
          <ReactPlayer
            url={video}
            controls={false}
            loop
            playing
            playsinline={true}
            muted={true}
            className="video-container2"
          
          />
        </div>
        <p className="md:text-[18px] text-[16px] font-normal leading-[27px] font-Suisse text-[#666666]">
          Our core expertise lies in the trading of petroleum products, active
          engagement in the oil and gas sector, and the distribution of
          petroleum chemicals and general commodities within the UAE. This
          commitment is underpinned by our affiliation with the prestigious ANZA
          Investment Group, reinforcing our position as a prominent presence in
          the industry.
        </p>
      </div>
    </div>
  );
};
export default FiveDecade;
