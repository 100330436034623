const KeloneProduct = () => {
  const content = [
    {
      id: 1,
      product: "COPPER",
      manufacture: "SKC",
      packing: "215 KG (Steel / PE Drum)",
    },
    {
      id: 2,
      product: "ZINC",
      manufacture: "SKC",
      packing: "215 KG (Steel / PE Drum)",
    },
    {
      id: 3,
      product: "HEAVY METAL SCRAP (HMS)",
      manufacture: "SKC",
      packing: "215 KG (Steel / PE Drum)",
    },
    {
      id: 4,
      product: "STAINLESS STEEL",
      manufacture: "SKC",
      packing: "215 KG (Steel / PE Drum)",
    },
    {
      id: 5,
      product: "ALUMINIUM",
      manufacture: "SKC",
      packing: "215 KG (Steel / PE Drum)",
    },
    {
      id: 6,
      product: "Dipropylene Glycol (Fragrance Grade)",
      manufacture: "SKC",
      packing: "215 KG (Steel / PE Drum)",
    },
    {
      id: 7,
      product: "Dipropylene Glycol",
      manufacture: "SKC",
      packing: "215 KG (Steel / PE Drum)",
    },
  ];

  const widthHandler = (id) => {
    if (id === 1 || id === 3 || id === 5 || id === 7 || id === 9) {
      return "md:w-[90%] lg:w-[80%]";
    }
  };

  return (
    <div>
      <div className="mx-[3%] md:mx-[5%] xl:mx-[8%] lg:pt-[50px] md:pt-[50px] pt-[40px] pb-5">
        <h2 className="text-[20px] md:text-[25px] lg:text-[35px] font-medium leading-[42px] font-Suisse text-[#000] lg:pb-[80px] pb-[40px] lg:text-left text-center">
          METALS PRODUCT LIST
        </h2>
        <div className="flex">
          <div className="w-[50%] mb-2">
            <h6 className="text-[16px] md:text-[19px] lg:text-[22px]  px-1 text-[#777777] font-Suisse font-normal leading-[29px] ">
              PRODUCTS
            </h6>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          {content?.map((item, index) => (
            <div
              className={`flex border-b-[1px] lg:py-8 py-4 ${widthHandler(
                item.id
              )}`}
              key={index}
            >
              <div>
                <h6 className="text-[15px] sm:text-[18px] lg:text-[20px] xl:text-[22px] text-[#04128A] font-Suisse font-semibold leading-[20px] sm:leading-[29px] px-1">
                  {item?.product}
                </h6>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <div className="w-full lg:pt-[100px] md:pt-[80px] pt-[60px] ">
            <img
              src={require("../../assets/img/kelone-img.png")}
              alt=""
              className="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default KeloneProduct;
